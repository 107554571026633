



















































































































import { ChatConfigSettings } from "@/includes/types/Chat/types";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";

import { Component, Emit, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    PageTitle,
    SwitchInput,
  }
})
export default class RemoveSystemSettings extends Mixins(InputSetups, TariffsTagsHelper) {
  @VModel() model!: ChatConfigSettings['common_config']

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  saveButtonClick() {
    return true
  }
}
