var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"journal-settings"},[_c('page-title',{attrs:{"title":_vm.$t('journal_page_title'),"subtitle":_vm.$t('journal_page_description')},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",attrs:{"disabled":_vm.disabled},on:{"click":_vm.saveButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{attrs:{"title":_vm.$t('field_bind_journal_channel_title')}},[(_vm.$store.state.chatState.chat.log_channel_id)?_c('div',{staticClass:"flex justify-between items-center"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$store.state.chatState.chat.log_channel_id)+" ")]),_vm._v(" - "+_vm._s(_vm.$t('bind_journal_active_info_1'))+" ")]),_c('a-button',{attrs:{"type":"danger","disabled":_vm.disabled},on:{"click":function($event){return _vm.$store.dispatch('runChatAction', 'resetlogchannel')}}},[_vm._v(" "+_vm._s(_vm.$t('dellog_journal'))+" ")])],1):_c('div',[_c('a-alert',{attrs:{"show-icon":""}},[_c('template',{slot:"message"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('bind_channel_code_info_1'))+" ")]),_c('p',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.bindChannelInfo)}})])],2),_c('bind-channel-by-code',{staticClass:"mt-2",attrs:{"bind-code-type":"Log","disabled":_vm.disabled},on:{"onChannelBind":_vm.handleOnChannelBind}})],1)]),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_change_name',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_moderation_all_ban',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_moderation_all_warn',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'allow_log_action_buttons',
          'hasAnchor': true,
          'disabled': _vm.disabled
        },
      }}}),_c('a-divider'),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_users',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_system',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_settings',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('a-divider'),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_antispam',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.model,
          'key': 'journal_another_bot',
          'hasAnchor': true,
          'disabled': _vm.disabled
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_triggers_title')}},[_c('a-alert',{staticClass:"mb-2",attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_triggers_help_message'))},slot:"message"})]),(!_vm.getTagsByFieldKey("binded_channel"))?[_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'send_to_journal_trigger_call',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'send_to_journal_trigger_message_delete',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})]:_c('not-available-options-overlay',{attrs:{"tags":_vm.getTagsByFieldKey('binded_channel')}})],2),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_card_users_title')}},[_c('a-alert',{staticClass:"mb-2",attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_users_help_message'))},slot:"message"})]),(!_vm.getTagsByFieldKey("binded_channel"))?[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'log_kick',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'log_leave',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),(_vm.$store.state.chatState.chat.version >= 31000)?_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'log_user_prop_transition',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}):_vm._e(),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.model,
            'key': 'log_restrict',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})]:_c('not-available-options-overlay',{attrs:{"tags":_vm.getTagsByFieldKey('binded_channel')}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }