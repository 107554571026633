












































import { _ModulesManager } from "@/includes/logic/Modules/ModulesManager";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ModulesErrors extends Vue {
  @Prop() errors!: _ModulesManager['errors']
}
