var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topic-settings"},[_c('page-title',{attrs:{"title":_vm.$t('topic_settings_page_title'),"subtitle":_vm.$t('common_settings_tab_page_description'),"help-message":_vm.$t('common_settings_tab_page_help_message')}},[_c('a-button',{attrs:{"slot":"custom-button","type":"primary","icon":"plus","disdabled":_vm.disabled},on:{"click":function($event){_vm.isAddTopicModalOpen = true}},slot:"custom-button"},[_vm._v(" "+_vm._s(_vm.$t('topics_add'))+" ")])],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('a-modal',{staticClass:"topic-settings__modal-new",attrs:{"title":_vm.$t('topics_add'),"destroy-on-close":"","after-close":_vm.initNewTopicModel,"ok-text":_vm.$t('save_button'),"ok-button-props":{
        props: {
          disabled: invalid
        }
      }},on:{"ok":_vm.addTopic},model:{value:(_vm.isAddTopicModalOpen),callback:function ($$v) {_vm.isAddTopicModalOpen=$$v},expression:"isAddTopicModalOpen"}},[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.newTopic,
            'key': 'title',
            'prefix': 'topics_',
            'validation': 'required'
          }
        }}}),_c('text-input',{staticClass:"mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.newTopic,
            'key': 'value',
            'prefix': 'topics_',
            'validation': 'required'
          }
        }}})],1),(_vm.currentTopic)?_c('a-modal',{staticClass:"topic-settings__modal-rename",attrs:{"destroy-on-close":"","title":_vm.$t('topics_modal_rename_title'),"after-close":_vm.resetCurrentTopic,"ok-text":_vm.$t('save_button'),"ok-button-props":{
        props: {
          disabled: invalid
        }
      }},on:{"ok":_vm.okClick,"cancel":_vm.resetCurrentTopic},model:{value:(_vm.isRenameTopicModalOpen),callback:function ($$v) {_vm.isRenameTopicModalOpen=$$v},expression:"isRenameTopicModalOpen"}},[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.currentTopic,
            'key': 'title',
            'prefix': 'topics_',
            'validation': 'required'
          }
        }}})],1):_vm._e()]}}])}),(_vm.topics.length)?_c('a-row',{staticClass:"topic-settings__items",attrs:{"gutter":[ 16, 16 ],"type":"flex"}},_vm._l((_vm.topics),function(topic){return _c('a-col',{key:topic.id,attrs:{"xs":24,"lg":12}},[_c('a-card',{attrs:{"size":"small"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(topic.title)+" "),_c('small',[_vm._v("("+_vm._s(topic.id)+")")])]},proxy:true},(!_vm.disabled)?{key:"actions",fn:function(){return [_c('a-icon',{key:"rename",attrs:{"type":"edit"},on:{"click":function($event){return _vm.renameTopic(topic)}}}),_c('a-icon',{key:"delete",attrs:{"type":"delete"},on:{"click":function($event){return _vm.removeTopic(topic.id)}}})]},proxy:true}:null],null,true)})],1)}),1):_c('empty-data')],1)}
var staticRenderFns = []

export { render, staticRenderFns }