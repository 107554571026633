






































enum CodeState {
  None = 'None',
  Loading = 'Loading',
}

export type BindChannelState = {
  binded: boolean,
  channel_id?: number
}

import Api from '@/includes/logic/Api'
import { errorNotification, successNotification } from '@/includes/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { copyTokenToClipboard } from 'piramis-base-components/src/logic/helpers/copy-to-clipboard'

import { Emit, Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component({
  data() {
    return {
      CodeState
    }
  },
})
export default class BindChannelByCode extends Mixins(UseFields) {

  @Prop({ type: String }) bindCodeType!: 'CustomChannel' | 'Log'

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit('onChannelBind')
  channelBindComplete(bindData: BindChannelState): BindChannelState {
    return bindData
  }

  currentCodeState = CodeState.None

  code: string | null = null

  timerId = 0

  codeLoading = false

  handleResult(bindData: BindChannelState): void {
    if (!bindData.binded) {
      this.currentCodeState = CodeState.Loading
      this.timerId = setTimeout(() => {
        this.getChannelState()
      }, 2500)
    } else {
      clearTimeout(this.timerId)
      this.currentCodeState = CodeState.None
      this.code = null
      this.channelBindComplete(bindData)
    }
  }

  async getChannelState(): Promise<void> {
    try {
      const { data } = await Api.stateChannelByCode('tg', { code: this.code })
      this.handleResult(data)
    } catch (e: any) {
      errorNotification(e)
    }
  }

  onCountdownFinish(): void {
    clearTimeout(this.timerId)
    this.currentCodeState = CodeState.None
    this.code = null
  }

  getBindCode(): void {
    Api.bindChannelByCode('tg', {
      chat_id: this.$store.state.chatState.chat.chat_id,
      type: this.bindCodeType
    })
      .then(({ data }) => {
        this.currentCodeState = CodeState.Loading
        this.code = data.code
        this.getChannelState()
      })
      .catch(errorNotification)
  }

  destroyed():void {
    clearTimeout(this.timerId)
  }

  copyToClipBoard(value: string) {
    copyTokenToClipboard(value)
    successNotification()
  }
}
